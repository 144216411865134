<template>
    <div>
        <a-list :dataSource="list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta>
                    <a slot="title" @click="openLink(item.file_url)" >{{item.file_name}}</a>
                    <a-avatar  slot="avatar" size="small" icon="link" />
                </a-list-item-meta>
            </a-list-item>
        </a-list>
    </div>
</template>
<script>
    export default {
        props: ["list"],
        methods: {
            openLink( link) {
                window.open(`${link}`, "_blank");
            },
        }
    };
</script>
<style>

</style>
