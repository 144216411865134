var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComposeMail", {
        key: _vm.newMailComponentKey,
        ref: "composeMail",
        attrs: {
          visible: _vm.visible,
          "is-admin": _vm.isAdmin,
          "reply-to": _vm.replyTo
        },
        on: { cancel: _vm.closeReplyModal, create: _vm.handleCreate }
      }),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { staticStyle: { "padding-bottom": "10px" }, attrs: { span: 22 } },
            [
              _c(
                "a-tooltip",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [
                  _c("a-icon", {
                    staticStyle: { size: "5rem", color: "#000" },
                    attrs: { type: "arrow-left" }
                  }),
                  _c("template", { slot: "title" }, [
                    _vm._v(
                      "Retour à " +
                        _vm._s(
                          _vm.active_key == _vm.BOITE_RECEPTION
                            ? "Boite de réception"
                            : "Messages envoyés"
                        ) +
                        "\n                "
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticStyle: { background: "#ECECEC", padding: "1px" } },
              [
                _c(
                  "a-card",
                  {
                    attrs: {
                      hoverable: "",
                      bordered: false,
                      loading: _vm.loading
                    }
                  },
                  [
                    _c(
                      "a-row",
                      { attrs: { type: "flex", justify: "space-around" } },
                      [
                        _c(
                          "a-col",
                          {
                            staticClass: "mrgin_btm",
                            attrs: { md: 20, xs: 24 }
                          },
                          [
                            _c("h4", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.message.subject) +
                                  "\n                            "
                              )
                            ]),
                            _c(
                              "a-card-meta",
                              {
                                attrs: {
                                  title: "",
                                  description: _vm.getDescriptionText(
                                    _vm.message
                                  )
                                }
                              },
                              [
                                _c("a-avatar", {
                                  attrs: {
                                    slot: "avatar",
                                    src:
                                      "https://www.shareicon.net/data/512x512/2016/10/20/846461_blue_512x512.png"
                                  },
                                  slot: "avatar"
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-col", { attrs: { md: 4, xs: 24 } }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.message
                                  ? _vm
                                      .moment(_vm.message.sent_at)
                                      .format("DD MMM YY HH:mm")
                                  : ""
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("a-textarea", {
                      staticStyle: { "margin-top": "20px", border: "none" },
                      attrs: {
                        placeholder: "Basic usage",
                        disabled: "",
                        value: _vm.message.message,
                        autosize: true
                      }
                    }),
                    _c(
                      "template",
                      { staticClass: "ant-card-actions", slot: "actions" },
                      [
                        _vm.showReplyButton(_vm.message)
                          ? _c(
                              "span",
                              {
                                staticClass: "mrg-t",
                                on: { click: _vm.showReplyModal }
                              },
                              [
                                _c("a-icon", { attrs: { type: "rollback" } }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v("Répondre")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _vm.attachements_list.length
            ? _c(
                "a-col",
                { attrs: { span: 20, loading: _vm.loading } },
                [
                  _c("AttachementsList", {
                    attrs: { list: _vm.attachements_list }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }