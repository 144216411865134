<template>
    <div>
        <ComposeMail
                ref="composeMail"
                :visible="visible"
                @cancel="closeReplyModal"
                :is-admin="isAdmin"
                :reply-to="replyTo"
                :key="newMailComponentKey"
                @create="handleCreate"
        />
        <a-row type="flex" justify="center">
            <a-col :span="22" style="padding-bottom: 10px">
                <a-tooltip @click="goBack()">
                    <a-icon type="arrow-left" style="size: 5rem ; color: #000;"/>

                    <template
                            slot="title"
                    >Retour à {{active_key==BOITE_RECEPTION?`Boite de réception`:`Messages envoyés`}}
                    </template>
                </a-tooltip>
            </a-col>
            <a-col :span="24">
                <div style="background:#ECECEC; padding:1px">
                    <a-card hoverable :bordered="false" :loading="loading">
                        <a-row type="flex" justify="space-around">
                            <a-col :md="20" :xs="24" class="mrgin_btm">
                                <h4>
                                    {{ message.subject}}
                                </h4>    
                                <a-card-meta
                                        title=""
                                        :description="getDescriptionText(message)"
                                >
                                    <a-avatar
                                            slot="avatar"
                                            src="https://www.shareicon.net/data/512x512/2016/10/20/846461_blue_512x512.png"
                                    />
                                </a-card-meta>
                            </a-col>
                            <a-col :md="4" :xs="24">
                                <p>{{message ? moment(message.sent_at).format("DD MMM YY HH:mm"):''}}</p>
                            </a-col>
                        </a-row>
                        <a-textarea style="margin-top: 20px; border: none" placeholder="Basic usage"  disabled
                                    :value="message.message"  :autosize="true"/>
                        <template class="ant-card-actions" slot="actions">
                          <span v-if="showReplyButton(message)" class="mrg-t" @click="showReplyModal">
                            <a-icon type="rollback"/>
                            <span style="margin-left: 5px;">Répondre</span>
                          </span>
                        </template>
                    </a-card>
                </div>
            </a-col>
            <a-col :span="20" :loading="loading"  v-if="attachements_list.length">
                <AttachementsList
                        :list="attachements_list"
                />
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import {mapState, mapActions, mapGetters} from "vuex";
    import moment from "moment";
    import constants from "@/const/const";
    import ComposeMail from "@/components/common/messagerie/NewMail";
    import AttachementsList from "@/components/common/messagerie/AttachementsList";

    export default {
        name: "ShowMail",
        created() {
            this.isAdmin = this.$route.params.isAdmin;
            this.fetchMessage();
        },
        components: {ComposeMail, AttachementsList},
        data() {
            return {
                isAdmin: null,
                visible: false,
                loading: true,
                replyTo: null,
                newMailComponentKey: 0,
                BOITE_RECEPTION: constants.BOITE_RECEPTION,
                MESSAGE_ENVOYE: constants.MESSAGE_ENVOYE
            };
        },
        computed: {
            ...mapState({
                message: state => state.messageries.message,
                attachements_list: state => state.messageries.message.attachements,
                all_unfiltered_adherents_emails_ids: state =>
                    state.messageries.users_emails
            }),
            ...mapGetters({active_key: "getSelectedTabKey"})
        },
        methods: {
            fetchMessage() {
                this.loading = true;
                this.getMessage({
                    id: this.$route.params.id
                }).then(() => (this.loading = false));
            },
            handleCreate() {
                const form = this.$refs.composeMail.form;
                form.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    let mail = {};
                    mail.listDestination = [];
                    mail.subject = values.subject;
                    mail.message = values.message;
                    // Means send to all
                    if (values.to.indexOf(-1) > -1) {
                        this.adherents_emails_ids.forEach(email_id => {
                            mail.listDestination.push({adherant: {id: `${email_id.id}`}});
                        });
                    } else {
                        values.to.forEach(email => {
                            // let id  = this.all_unfiltered_adherents_emails_ids[email];
                            let id = this.getAdherentByEmail[email];
                            mail.listDestination.push({adherant: {id: `${id}`}});
                        });
                    }
                    this.send({sender: this.SENT_BY_ADMIN, email: mail})
                        .then(response => {
                            this.$_showSuccessMessage(`${constants.MESSAGE_SENT}`, 5);
                            this.visible = false;
                            form.resetFields();
                        })
                        .catch(() => {
                            this.$_throwAnError(`${constants.MESSAGE_SENT_FAIL}`, 5);
                        });
                });
            },
            showReplyButton(message) {
                if (this.isAdmin && message.isInOut) {
                    return true;
                }
                return false;
            },
            closeReplyModal() {
                this.visible = false;
            },
            showReplyModal() {
                this.replyTo = this.message.user.email;
                this.genererateNewPaiementEditorKey();
                this.visible = true;
            },
            genererateNewPaiementEditorKey() {
                this.newMailComponentKey = Math.floor(Math.random() * 10 + 1);
            },
            getDescriptionText(message) {
                if (message.destination) {
                    if (this.isAdmin) {
                        return `SOR A ${message.destination}`;
                    }
                    return "Moi A SOR";
                }
                if (this.isAdmin) {
                    return `${message.user.nom_fr} ${message.user.prenom_fr} A Moi`;
                }
                return `SOR A Moi`;
            },

            ...mapActions({
                getMessage: "fetchMessageById",
                getUsersEmails: "fetchUsersEmails",
                clearMessage: "cleanSelectedMessage",
                markAsRead: "markMessageAsRead",
                send: "sendMessage"
            }),
            moment,
            goBack() {
                this.$router.go(-1);
            }
        },
        beforeDestroy() {
            this.markAsRead(this.message.id);
            this.clearMessage();
        }
    };
</script>

<style scoped>
    .ant-card-head {
        border: none;
    }
    .ant-input-disabled {
        color: unset;
        background-color: unset;
    }
    .mrg-t {
        margin-top: 3%;
    }
    .mrgin_btm {
         margin-bottom: 5%;
    }
</style>