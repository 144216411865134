var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-list", {
        attrs: { dataSource: _vm.list },
        scopedSlots: _vm._u([
          {
            key: "renderItem",
            fn: function(item, index) {
              return _c(
                "a-list-item",
                {},
                [
                  _c(
                    "a-list-item-meta",
                    [
                      _c(
                        "a",
                        {
                          attrs: { slot: "title" },
                          on: {
                            click: function($event) {
                              return _vm.openLink(item.file_url)
                            }
                          },
                          slot: "title"
                        },
                        [_vm._v(_vm._s(item.file_name))]
                      ),
                      _c("a-avatar", {
                        attrs: { slot: "avatar", size: "small", icon: "link" },
                        slot: "avatar"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }